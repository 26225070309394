import React, {Component} from 'react';
import $ from "jquery";
import Box, {Item} from 'devextreme-react/box';
import LDH from '../helpers/LeopardDataHelper';
import LeopardDataHelper from '../helpers/LeopardDataHelper';
import {NumberBox, SelectBox, TextBox} from 'devextreme-react';
import LeopardStaticUIConfig from "./LeopardStaticUIConfig";
import LRH from "../helpers/LeopardReactHelper";
import LeopardTooltipWithLink from "../datashaping/LeopardTooltipWithLink";
import DateBox from "devextreme-react/date-box";
import {PatternRule, RequiredRule} from 'devextreme-react/validator';
import Validator from 'devextreme-react/ui/validator';
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";
import ValidationEngine from 'devextreme/ui/validation_engine';
import LWH from "../helpers/LeopardWebsocketHelper";

class LeopardFormWidgetEngine extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formInstance: null,
            isWidgetInitialized: false,
        };

        this.selectedParentViewData = null;
        this.selectedGrandParentViewData = null;
        this.uiObjectInstances = [];
        this.formDefinition = [];
        this.dataViewId = null;
        this.relationshipsLinkedToDataView = [];
        this.formDataToSubmit = {};
        this.relationships = [];
        this.formSubmitMethod = "";
    }

    componentDidMount() {
        this.formDefinition = this.props.formDefinition;
        this.dataViewId = this.props.dataViewId;
        this.relationships = this.props.relationships;

        if (!LDH.IsObjectNull(this.formDefinition.parameters)) {
            for (let i = 0; i < this.formDefinition.parameters.length; i++) {
                let columnName = this.formDefinition.parameters[i].columnName;
                if (LDH.IsObjectNull(columnName) || LDH.IsValueEmpty(columnName)) {
                    continue;
                }
                let defaultValue = this.formDefinition.parameters[i].defaultValue;
                this.formDataToSubmit[columnName] = defaultValue;
            }
        }
        this.establishRelationships();
        this.setState({isWidgetInitialized: true});
    }

    componentWillUnmount = () => {
        let formDefinition = this.formDefinition;
        LWH.RemovePendingEventByDataViewId(this.dataViewId);
        LRH.DisposeUIInstancesFromList(this.uiObjectInstances);

        if (!LDH.IsObjectNull(formDefinition.parameters) && formDefinition.parameters.length > 0) {
            for (let k = 0; k < formDefinition.parameters.length; k++) {
                let controlId = formDefinition.parameters[k].parameterName;
                let controlName = "dataViewParam_" + this.dataViewId + "_control_" + controlId;
                if (!LDH.IsObjectNull(window[controlName])) {
                    delete window[controlName];
                }
            }
        }
    };

    setInputFieldInstance = (data) => {
        if (data.e === undefined || data.e === null || data.e.instance === null) {
            return;
        }
        let instances = this.uiObjectInstances;
        instances[data.input] = data.e.instance;
    };

    setUIInstance = (data) => {
        if (data.e === undefined || data.e === null) {
            return;
        }
        let instances = this.uiObjectInstances;
        instances[data.name] = data.e;
    };

    customValidationRuleCallback = (data) => {
        let instances = this.uiObjectInstances;
        return LRH.BindValidationRulesToUIObject(data, instances);
    };

    inputFieldValueOnChanged = (data) => {
        this.formDataToSubmit[data.fieldName] = data.fieldValue;
    };

    establishRelationships = () => {
        let that = this;
        that.relationships = that.relationships;
        let dashboardItemId = that.dataViewId;
        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships) &&
            that.relationshipsLinkedToDataView.length === 0) {
            let linkedList = LDH.GetRelationshipsByDashboardItemId(that.relationships,
                dashboardItemId);
            that.relationshipsLinkedToDataView = linkedList;
        }

        that.selectedParentViewData = null;
        that.selectedGrandParentViewData = null;

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships)) {
            LeopardStaticUIConfig.Global_DashboardDataViewListeners.push({
                dashboardItemId,
                props: that.props,
                instance: "blank",
                callback(data) {
                    var dataViewId = that.props.GridViewId;
                    if (!LDH.IsObjectNull(that.props.dataViewId) &&
                        !LDH.IsValueEmpty(that.props.dataViewId)) {
                        dataViewId = that.props.dataViewId;
                    }

                    for (let i = 0; i < data.features.length; i++) {
                        if (data.features[i] === "rowlink") {
                            that.selectedParentViewData = data.dataFromSource;
                            that.selectedGrandParentViewData = data.dataFromParent;
                            that.clearAllFieldsData();

                            if (!LDH.IsObjectNull(data.dataFromSource)) {
                                if (!LDH.IsObjectNull(that.formDefinition.apiEnabled) &&
                                    that.formDefinition.apiEnabled === true) {
                                    let odataQuery = that.formDefinition.apiODataQuery;
                                    let useEventSync = that.formDefinition.apiUseEventSync;
                                    let responseDataFromAPI = [];

                                    if ((!LDH.IsObjectNull(odataQuery) && !LDH.IsValueEmpty(odataQuery)) ||
                                        (!LDH.IsObjectNull(odataQuery) && useEventSync)) {
                                        let javascript = odataQuery;
                                        let dataName = "parentRowData";
                                        let dataValue = {
                                            parentData: that.selectedParentViewData,
                                            grandParentData: that.selectedGrandParentViewData
                                        };
                                        odataQuery = LDH.FilterMacro(odataQuery);
                                        odataQuery = LDH.EvaluateJavaScriptForDataShaping(javascript,
                                            dataName, dataValue, that.dataViewId);

                                        let postTemplate = "";
                                        if (!LDH.IsObjectNull(that.formDefinition.apiODataHttpPostBody) &&
                                            !LDH.IsValueEmpty(that.formDefinition.apiODataHttpPostBody)) {
                                            let javascript = that.formDefinition.apiODataHttpPostBody;
                                            let dataName = "parentRowData";
                                            let dataValue = {
                                                parentData: that.selectedParentViewData,
                                                grandParentData: that.selectedGrandParentViewData
                                            };
                                            javascript = LDH.FilterMacro(javascript);
                                            postTemplate = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                                                dataValue, dataViewId);
                                        }

                                        if (!LDH.IsObjectNull(that.formDefinition.apiUseEventSync) &&
                                            !LDH.IsValueEmpty(that.formDefinition.apiUseEventSync) &&
                                            that.formDefinition.apiUseEventSync === true) {

                                            $("#gridViewToobar_" + dataViewId).show();
                                            LeopardAjaxHelper.SendRequestByEventSync(function (response) {
                                                responseDataFromAPI = response.body.data;
                                                if (!LDH.IsObjectNull(that.formDefinition.apiPostDataScript) &&
                                                    !LDH.IsValueEmpty(that.formDefinition.apiPostDataScript)) {
                                                    let javascript = that.formDefinition.apiPostDataScript;
                                                    let dataName = "responseData";
                                                    let dataValue = responseDataFromAPI;
                                                    responseDataFromAPI = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                                                        dataValue, dataViewId);
                                                }
                                                that.populateDataIntoFields(data, responseDataFromAPI);
                                                $("#gridViewToobar_" + dataViewId).hide();
                                            }, function (error, sessionTimeout) {
                                                if (sessionTimeout !== undefined && sessionTimeout === true) {
                                                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                                                } else {
                                                    LRH.ShowToast("Failed to process your request. " +
                                                        "Please check your network connection status and try again.", "error", 5000);
                                                }
                                                $("#gridViewToobar_" + dataViewId).hide();
                                            }, postTemplate, "not-required");
                                        } else {
                                            $("#gridViewToobar_" + dataViewId).show();
                                            LeopardAjaxHelper.GenericHttpRequest("get", odataQuery, null, function (response) {
                                                responseDataFromAPI = response;
                                                if (!LDH.IsObjectNull(that.formDefinition.apiPostDataScript) &&
                                                    !LDH.IsValueEmpty(that.formDefinition.apiPostDataScript)) {
                                                    let javascript = that.formDefinition.apiPostDataScript;
                                                    let dataName = "responseData";
                                                    let dataValue = responseDataFromAPI;
                                                    responseDataFromAPI = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                                                        dataValue, dataViewId);
                                                }
                                                that.populateDataIntoFields(data, responseDataFromAPI);
                                                $("#gridViewToobar_" + dataViewId).hide();
                                            }, function (error, sessionTimeout) {
                                                if (sessionTimeout !== undefined && sessionTimeout === true) {
                                                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                                                } else {
                                                    LRH.ShowToast("Failed to process your request. " +
                                                        "Please check your network connection status and try again.", "error", 5000);
                                                }
                                                $("#gridViewToobar_" + dataViewId).hide();
                                            }, false, true);
                                        }
                                    } else {
                                        if (!LDH.IsObjectNull(that.formDefinition.apiPostDataScript) &&
                                            !LDH.IsValueEmpty(that.formDefinition.apiPostDataScript)) {
                                            let javascript = that.formDefinition.apiPostDataScript;
                                            let dataName = "responseData";
                                            let dataValue = [];
                                            responseDataFromAPI = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                                                dataValue, dataViewId);
                                        }
                                        that.populateDataIntoFields(data, responseDataFromAPI);
                                    }
                                }
                            }
                        }
                    }
                }
            });
        }
    };

    clearAllFieldsData = () => {
        let that = this;

        if (!LDH.IsObjectNull(that.formDefinition.parameters) &&
            !LDH.IsValueEmpty(that.formDefinition.parameters)) {
            let parametersCloned = LDH.DeepClone(that.formDefinition.parameters);

            for (let x = 0; x < parametersCloned.length; x++) {
                let item = parametersCloned[x];
                let input = that.uiObjectInstances["formField_" +
                item.fieldName + "Input_" + that.dataViewId];
                if (!LDH.IsObjectNull(input)) input.option("value", "");
            }
        }
    }

    populateDataIntoFields = (data, responseDataFromAPI) => {
        let that = this;

        if (!LDH.IsObjectNull(responseDataFromAPI) && typeof responseDataFromAPI === "object") {
            let responseKeys = Object.keys(responseDataFromAPI);
            if (LDH.IsObjectNull(data.dataFromSource)) {
                data.dataFromSource = [];
            }
            for (let x = 0; x < responseKeys.length; x++) {
                let keyValue = responseKeys[x];
                let keyData = responseDataFromAPI[keyValue];
                data.dataFromSource[keyValue] = keyData;
            }
        }

        if (!LDH.IsObjectNull(that.formDefinition.parameters) &&
            !LDH.IsValueEmpty(that.formDefinition.parameters)) {
            let parametersCloned = LDH.DeepClone(that.formDefinition.parameters);

            for (let x = 0; x < parametersCloned.length; x++) {
                let item = parametersCloned[x];
                if (!LDH.IsObjectNull(item.parentFieldName) && !LDH.IsValueEmpty(item.parentFieldName)) {
                    let sourceObjects = Object.keys(data.dataFromSource);
                    for (let k = 0; k < sourceObjects.length; k++) {
                        if (sourceObjects[k] === item.parentFieldName &&
                            (LDH.IsObjectNull(item.defaultValue) || LDH.IsValueEmpty(item.defaultValue))) {
                            item.fieldValue = data.dataFromSource[sourceObjects[k]];

                            let input = that.uiObjectInstances["formField_" +
                            item.fieldName + "Input_" + that.dataViewId];
                            if (!LDH.IsObjectNull(input) &&
                                (LDH.IsObjectNull(that.formDefinition.enableParentDataDisplay) ||
                                    that.formDefinition.enableParentDataDisplay)) {
                                input.option("value", item.fieldValue);
                            }
                            break;
                        }
                    }
                }
                if (!LDH.IsObjectNull(item.columnName) && !LDH.IsValueEmpty(item.columnName)) {
                    let sourceObjects = Object.keys(data.dataFromSource);
                    for (let k = 0; k < sourceObjects.length; k++) {
                        if (sourceObjects[k] === item.columnName &&
                            (LDH.IsObjectNull(sourceObjects[sourceObjects[k]]) ||
                                LDH.IsValueEmpty(sourceObjects[sourceObjects[k]]))) {

                            let input = that.uiObjectInstances["formField_" +
                            item.fieldName + "Input_" + that.dataViewId];
                            if (!LDH.IsObjectNull(input)) {
                                input.option("value", data.dataFromSource[sourceObjects[k]]);
                            }
                            break;
                        }
                    }
                }
            }
            that.formDefinition.parameters = parametersCloned;
        }
    }

    clearFormDataAndAutoFocus = () => {
        let that = this;
        let firstInputbox = false;
        if (LDH.IsObjectNull(that.formDefinition.clearFormData) ||
            that.formDefinition.clearFormData === true) {
            let keys = Object.keys(that.uiObjectInstances);
            for (let k = 0; k < keys.length; k++) {
                if (!keys[k].toString().startsWith("formField_")) {
                    continue;
                }
                that.uiObjectInstances[keys[k]].option("value", null);
                if (firstInputbox === false) {
                    that.uiObjectInstances[keys[k]].focus();
                }
                firstInputbox = true;
            }
            ValidationEngine.resetGroup("validation-group-1");
        }
    };

    formSubmissionLogic = (odataQuery, postDataScript, directory, filename, submissionType) => {
        let that = this;
        let requestBody = LDH.DeepClone(this.formDataToSubmit);

        let url = odataQuery;
        if (!LDH.IsObjectNull(odataQuery) && !LDH.IsValueEmpty(odataQuery)) {
            let javascript = odataQuery;
            let dataName = "parentRowData";
            let dataValue = {
                parentData: this.selectedParentViewData,
                grandParentData: this.selectedGrandParentViewData
            };
            url = LDH.FilterMacro(url);
            url = LDH.EvaluateJavaScriptForDataShaping(javascript,
                dataName, dataValue, that.dataViewId);
        }

        if (!LDH.IsObjectNull(postDataScript) && !LDH.IsValueEmpty(postDataScript)) {
            let javascript = postDataScript;
            let dataName = "formData";
            requestBody = LDH.EvaluateJavaScriptForDataShaping(javascript,
                dataName, requestBody, that.dataViewId);
        }

        if (!LDH.IsObjectNull(directory) && !LDH.IsValueEmpty(directory) &&
            !LDH.IsObjectNull(filename) && !LDH.IsValueEmpty(filename)) {
            let profile = window.userProfile;
            let userId = LeopardDataHelper.GetUserIdFromUserProfile(profile);

            $("#gridViewToobar_" + that.dataViewId).show();
            that.uiObjectInstances["formeditor_topbar_update"].option("disabled", true);
            that.uiObjectInstances["formeditor_topbar_create"].option("disabled", true);

            LeopardAjaxHelper.RetrieveDocumentFromS3(userId, filename, directory, function (documentData) {
                documentData = LDH.FilterMacro(documentData);

                let grandParentData = that.selectedParentViewData;
                if (!LDH.IsObjectNull(that.formDefinition.enableGrandParentData) &&
                    that.formDefinition.enableGrandParentData === true) {
                    grandParentData = that.selectedGrandParentViewData;
                }

                if (!LDH.IsObjectNull(grandParentData)) {
                    let parentKeys = Object.keys(grandParentData);

                    for (let k = 0; k < parentKeys.length; k++) {
                        let parentValue = grandParentData[parentKeys[k]];
                        documentData = LDH.ReplaceAll(documentData, "{Parent_" + parentKeys[k] + "}", parentValue);
                    }
                }

                let keys = Object.keys(requestBody);
                for (let v = 0; v < keys.length; v++) {
                    let formValue = requestBody[keys[v]];
                    documentData = LDH.ReplaceAll(documentData, "{" + keys[v] + "}", formValue);
                }
                documentData = JSON.parse(documentData);

                if (submissionType === "create") {
                    LeopardAjaxHelper.GridViewCRUD_InsertData(url, documentData, function () {
                        $("#gridViewToobar_" + that.dataViewId).hide();
                        that.uiObjectInstances["formeditor_topbar_update"].option("disabled", false);
                        that.uiObjectInstances["formeditor_topbar_create"].option("disabled", false);

                        that.clearFormDataAndAutoFocus();
                        LRH.ShowToast("The data has been successfully added.", "success", 5000);
                    }, function (ex) {
                        $("#gridViewToobar_" + that.dataViewId).hide();
                        that.uiObjectInstances["formeditor_topbar_update"].option("disabled", false);
                        that.uiObjectInstances["formeditor_topbar_create"].option("disabled", false);
                        LRH.ShowToast("Failed to add the data to the table. " +
                            JSON.stringify(ex.message), "error", 5000);
                    }, false);
                } else if (submissionType === "update") {
                    LeopardAjaxHelper.GridViewCRUD_UpdateData(url, documentData, "", function () {
                        $("#gridViewToobar_" + that.dataViewId).hide();
                        that.uiObjectInstances["formeditor_topbar_update"].option("disabled", false);
                        that.uiObjectInstances["formeditor_topbar_create"].option("disabled", false);

                        that.clearFormDataAndAutoFocus();
                        LRH.ShowToast("The data has been successfully updated.", "success", 5000);
                    }, function (ex) {
                        $("#gridViewToobar_" + that.dataViewId).hide();
                        that.uiObjectInstances["formeditor_topbar_update"].option("disabled", false);
                        that.uiObjectInstances["formeditor_topbar_create"].option("disabled", false);
                        LRH.ShowToast("Failed to add the data to the table. " +
                            JSON.stringify(ex.message), "error", 5000);
                    }, true);
                }
            }, function () {
                $("#gridViewToobar_" + that.dataViewId).hide();
                that.uiObjectInstances["formeditor_topbar_update"].option("disabled", false);
                that.uiObjectInstances["formeditor_topbar_create"].option("disabled", false);
                LRH.ShowToast("Failed to download the template from S3 bucket.", "error", 5000);
            });
        } else {
            if (submissionType === "create") {
                LeopardAjaxHelper.GridViewCRUD_InsertData(url, requestBody, function () {
                    $("#gridViewToobar_" + that.dataViewId).hide();
                    that.uiObjectInstances["formeditor_topbar_update"].option("disabled", false);
                    that.uiObjectInstances["formeditor_topbar_create"].option("disabled", false);

                    that.clearFormDataAndAutoFocus();
                    LRH.ShowToast("The data has been successfully added.", "success", 5000);
                }, function (ex) {
                    $("#gridViewToobar_" + that.dataViewId).hide();
                    that.uiObjectInstances["formeditor_topbar_update"].option("disabled", false);
                    that.uiObjectInstances["formeditor_topbar_create"].option("disabled", false);
                    LRH.ShowToast("Failed to add the data to the table. " +
                        JSON.stringify(ex.message), "error", 5000);
                });
            } else if (submissionType === "update") {
                LeopardAjaxHelper.GridViewCRUD_UpdateData(url, requestBody, "", function () {
                    $("#gridViewToobar_" + that.dataViewId).hide();
                    that.uiObjectInstances["formeditor_topbar_update"].option("disabled", false);
                    that.uiObjectInstances["formeditor_topbar_create"].option("disabled", false);

                    that.clearFormDataAndAutoFocus();
                    LRH.ShowToast("The data has been successfully updated.", "success", 5000);
                }, function (ex) {
                    $("#gridViewToobar_" + that.dataViewId).hide();
                    that.uiObjectInstances["formeditor_topbar_update"].option("disabled", false);
                    that.uiObjectInstances["formeditor_topbar_create"].option("disabled", false);
                    LRH.ShowToast("Failed to add the data to the table. " +
                        JSON.stringify(ex.message), "error", 5000);
                }, true);
            }
        }
    };

    formSubmit = (e) => {
        let validationGroup = ValidationEngine.getGroupConfig("validation-group-1");
        let validate = validationGroup.validate();
        e.preventDefault();

        if (this.formSubmitMethod === "create" && validate.isValid) {
            let odataQuery = this.formDefinition.dataCreateODataQuery;
            let script = this.formDefinition.dataCreatePostDataScript;
            let directory = this.formDefinition.dataCreateTemplateDirectory;
            let filename = this.formDefinition.dataCreateTemplateFilename;
            let method = this.formSubmitMethod;
            this.formSubmissionLogic(odataQuery, script, directory, filename, method);
        } else if (this.formSubmitMethod === "update" && validate.isValid) {
            let odataQuery = this.formDefinition.dataUpdateODataQuery;
            let script = this.formDefinition.dataUpdatePostDataScript;
            let directory = this.formDefinition.dataUpdateTemplateDirectory;
            let filename = this.formDefinition.dataUpdateTemplateFilename;
            let method = this.formSubmitMethod;
            this.formSubmissionLogic(odataQuery, script, directory, filename, method);
        }
    };

    formToolbar = (formDefinition) => {
        let that = this;
        return (
            <React.Fragment>
                <div className={"leopard-formeditor-toolbar"} style={{minHeight: "30px"}}>
                    <span id={"gridViewToobar_" + that.dataViewId} className="leopard-gridview-dataloading">
                        <i className="fas fa-spinner fa-pulse" style={{color: "#FF8100", fontSize: "18px"}}></i>
                    </span>
                    <span style={{padding: "0 2px 0 0"}}>
                            <LeopardTooltipWithLink
                                controlVisible={(LDH.IsObjectNull(formDefinition.dataUpdateEnableControl) ||
                                    LDH.IsValueEmpty(formDefinition.dataUpdateEnableControl))
                                    ? true : formDefinition.dataUpdateEnableControl}
                                elementId={"FormEditor_TopBar_Update_" + that.dataViewId}
                                refName={"formeditor_topbar_update"}
                                refFunc={(e) => this.setUIInstance(e)}
                                labelText={"Update"} width={250} gridViewId={that.dataViewId}
                                onClick={(e) => this.formEditorUpdateOnClick({e})}
                                useSubmitBehavior={true}/>
                    </span>
                    <span style={{padding: "0 2px 0 0"}}>
                            <LeopardTooltipWithLink
                                controlVisible={(LDH.IsObjectNull(formDefinition.dataCreateEnableControl) ||
                                    LDH.IsValueEmpty(formDefinition.dataCreateEnableControl))
                                    ? true : formDefinition.dataCreateEnableControl}
                                elementId={"FormEditor_TopBar_Create_" + that.dataViewId}
                                refName={"formeditor_topbar_create"}
                                refFunc={(e) => this.setUIInstance(e)}
                                labelText={"Create"} width={250} gridViewId={that.dataViewId}
                                onClick={(e) => this.formEditorCreateOnClick({e})}
                                useSubmitBehavior={true}/>
                    </span>
                </div>
            </React.Fragment>
        )
    };

    formEditorCreateOnClick = (e) => {
        this.formSubmitMethod = "create";
    };

    formEditorUpdateOnClick = (e) => {
        this.formSubmitMethod = "update";
    };

    generateValidationRules = (data, fieldName, parameter) => {
        if (parameter.isReadOnly === true) return null;

        let jsonData = (LDH.IsObjectNull(data) || LDH.IsValueEmpty(data)) ? [] : JSON.parse(data);
        return jsonData.map(function (validationItem, i) {
            let key = "validationRules_" + fieldName + "_patternRule_" + i;

            if (validationItem.regex === "[required]") {
                return <RequiredRule key={key} message={validationItem.error} trim={true}/>
            }
            return <PatternRule key={key} message={validationItem.error} pattern={validationItem.regex}/>
        });
    };

    renderBoxItems = (rowIndex, totalColumnCount, formDefinition) => {
        let that = this;
        let result = [];
        let currentIndexCount = 0;

        for (let i = 0; i < formDefinition.parameters.length; i++) {
            let parameter = formDefinition.parameters[i];
            if (!LDH.IsObjectNull(parameter["rendered"]) && parameter["rendered"]) {
                continue;
            }
            if (currentIndexCount >= totalColumnCount) {
                continue;
            }
            parameter["rendered"] = true;
            currentIndexCount++;

            if (LDH.IsObjectNull(parameter.fieldType) || LDH.IsValueEmpty(parameter.fieldType) || parameter.fieldType === "textbox") {
                result.push(
                    <Item ratio={1} key={"row_" + i}>
                        <div style={{paddingLeft: "20px"}}>
                            <div style={{paddingBottom: "5px", marginTop: "10px"}}>
                                {parameter.fieldDisplayName}
                            </div>
                            <div>
                                <TextBox readOnly={parameter.isReadOnly}
                                         defaultValue={LDH.IsObjectNull(parameter.defaultValue) ? "" : parameter.defaultValue}
                                         id={"formField_" + parameter.fieldName + "Input_" + that.dataViewId}
                                         onValueChanged={
                                             (e) => that.inputFieldValueOnChanged({
                                                 fieldName: parameter.columnName,
                                                 fieldValue: e.value
                                             })}
                                         ref={(e) => that.setInputFieldInstance({
                                             e,
                                             input: "formField_" + parameter.fieldName + "Input_" + that.dataViewId
                                         })}>
                                    <Validator validationGroup={"validation-group-1"}>
                                        {(LDH.IsObjectNull(parameter.validationRules) || LDH.IsValueEmpty(parameter.validationRules)) ? null :
                                            that.generateValidationRules(parameter.validationRules, parameter.fieldName, parameter)}
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                    </Item>);
            } else if (parameter.fieldType === "date" || parameter.fieldType === "datetime") {
                result.push(
                    <Item ratio={1} key={"row_" + i}>
                        <div style={{paddingLeft: "20px"}}>
                            <div style={{paddingBottom: "5px", marginTop: "10px"}}>
                                {parameter.fieldDisplayName}
                            </div>
                            <div>
                                <DateBox
                                    type={parameter.fieldType}
                                    displayFormat={(LDH.IsObjectNull(parameter.dateFormat) || LDH.IsValueEmpty(parameter.dateFormat)) ? "" : parameter.dateFormat}
                                    defaultValue={LDH.IsObjectNull(parameter.defaultValue) ? null : parameter.defaultValue}
                                    id={"formField_" + parameter.fieldName + "Input_" + that.dataViewId}
                                    onValueChanged={(e) => that.inputFieldValueOnChanged({
                                        fieldName: parameter.columnName,
                                        fieldValue: e.value
                                    })}
                                    ref={(e) => that.setInputFieldInstance({
                                        e,
                                        input: "formField_" + parameter.fieldName + "Input_" + that.dataViewId
                                    })}>
                                    <Validator validationGroup={"validation-group-1"}>
                                        {(LDH.IsObjectNull(parameter.validationRules) || LDH.IsValueEmpty(parameter.validationRules)) ? null :
                                            that.generateValidationRules(parameter.validationRules, parameter.fieldName, parameter)}
                                    </Validator>
                                </DateBox>
                            </div>
                        </div>
                    </Item>);
            } else if (parameter.fieldType === "numberbox") {
                result.push(
                    <Item ratio={1} key={"row_" + i}>
                        <div style={{paddingLeft: "20px"}}>
                            <div style={{paddingBottom: "5px", marginTop: "10px"}}>
                                {parameter.fieldDisplayName}
                            </div>
                            <div>
                                <NumberBox
                                    defaultValue={LDH.IsObjectNull(parameter.defaultValue) ? "" : parameter.defaultValue}
                                    id={"formField_" + parameter.fieldName + "Input_" + that.dataViewId}
                                    showSpinButtons={true} readOnly={parameter.isReadOnly}
                                    format={(!LDH.IsObjectNull(parameter.allowDecimal) && parameter.allowDecimal === true) ? "#" : ""}
                                    min={(LDH.IsObjectNull(parameter.minValue) || LDH.IsValueEmpty(parameter.minValue)) ? -999999 : parameter.minValue}
                                    max={(LDH.IsObjectNull(parameter.maxValue) || LDH.IsValueEmpty(parameter.maxValue)) ? 999999 : parameter.maxValue}
                                    onValueChanged={(e) => that.inputFieldValueOnChanged({
                                        fieldName: parameter.columnName,
                                        fieldValue: e.value
                                    })}
                                    ref={(e) => that.setInputFieldInstance({
                                        e,
                                        input: "formField_" + parameter.fieldName + "Input_" + that.dataViewId
                                    })}>
                                    <Validator validationGroup={"validation-group-1"}>
                                        {(LDH.IsObjectNull(parameter.validationRules) || LDH.IsValueEmpty(parameter.validationRules)) ? null :
                                            that.generateValidationRules(parameter.validationRules, parameter.fieldName, parameter)}
                                    </Validator>
                                </NumberBox>
                            </div>
                        </div>
                    </Item>);
            } else if (parameter.fieldType === "dropdown") {
                result.push(
                    <Item ratio={1} key={"row_" + i}>
                        <div style={{paddingLeft: "20px"}}>
                            <div style={{paddingBottom: "5px", marginTop: "10px"}}>
                                {parameter.fieldDisplayName}
                            </div>
                            <div>
                                <SelectBox displayExpr={'name'} valueExpr={'id'}
                                           defaultValue={LDH.IsObjectNull(parameter.defaultValue) ? "" : parameter.defaultValue}
                                           dataSource={LDH.IsObjectNull(parameter.dropdownValues) ? "" : parameter.dropdownValues}
                                           readOnly={parameter.isReadOnly}
                                           id={"formField_" + parameter.fieldName + "Input_" + that.dataViewId}
                                           onValueChanged={(e) => that.inputFieldValueOnChanged({
                                               fieldName: parameter.columnName,
                                               fieldValue: e.value
                                           })}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "formField_" + parameter.fieldName + "Input_" + that.dataViewId
                                           })}>
                                    <Validator validationGroup={"validation-group-1"}>
                                        {(LDH.IsObjectNull(parameter.validationRules) || LDH.IsValueEmpty(parameter.validationRules)) ? null :
                                            that.generateValidationRules(parameter.validationRules, parameter.fieldName, parameter)}
                                    </Validator>
                                </SelectBox>
                            </div>
                        </div>
                    </Item>);
            }
        }
        return result;
    };

    render() {
        let that = this;
        if (this.state.isWidgetInitialized === false) {
            return null;
        }
        let formDefinition = LDH.DeepClone(this.formDefinition);
        let totalColumnCount = formDefinition.columnCount;
        if (LDH.IsObjectNull(totalColumnCount) || LDH.IsValueEmpty(totalColumnCount)) {
            totalColumnCount = 4;
        }

        if (LDH.IsObjectNull(formDefinition.parameters)) {
            formDefinition.parameters = [];
        }

        for (let j = 0; j < formDefinition.parameters.length; j++) {
            formDefinition.parameters[j]["rendered"] = false;
            if (LDH.IsObjectNull(formDefinition.parameters[j]["displayOrder"]) ||
                LDH.IsValueEmpty(formDefinition.parameters[j]["displayOrder"])) {
                formDefinition.parameters[j]["displayOrder"] = 0;
            }
        }

        formDefinition.parameters.sort(function (a, b) {
            if (a.displayOrder < b.displayOrder) {
                return -1;
            }
            if (a.displayOrder > b.displayOrder) {
                return 1;
            }
            return 0;
        });

        return (
            <React.Fragment>
                <form onSubmit={this.formSubmit} style={{height: "calc(100% - 26px)", overflowY: "auto"}}>
                    {this.formToolbar(formDefinition)}
                    <div className={"leopard-formeditor-container"}>
                        {
                            formDefinition.parameters.map(function (parameter, index) {
                                return index % totalColumnCount === 0 ? (
                                    <Box direction="row" width="100%" key={"column_" + index}>
                                        {
                                            that.renderBoxItems(index, totalColumnCount, formDefinition)
                                        }
                                    </Box>) : null
                            })
                        }
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default LeopardFormWidgetEngine;